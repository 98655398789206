<template>
  <div class="h2 mb-4 text-secondary">New Company</div>
  <form>
    <div class="form-group">
      <label for="newCompanyName">Create New Company</label>
      <input
        id="newCompanyName"
        v-model="newCompanyName"
        type="text"
        class="form-control"
        placeholder="New company name"
      >
    </div>
    <button class="btn btn-success" @click.prevent="createNewCompany(); clear();">Create New Company</button>
  </form>
  <div class="h2 mb-4 mt-4 text-secondary">List of Companies</div>
  <div class="mb-3">
    <p v-show="errors" class="text-danger pt-3"> {{ errors }}</p>
    <p v-show="success" class="text-success pt-3"> {{ success }}</p>
  </div>
  <table class="table table-sm table-striped table-borderless">
    <thead>
      <tr>
        <th
          scope="col"
          style="width: 5%"
          class="text-center"
        >
          #
        </th>
        <th scope="col" style="width: 60%">Company Name</th>
        <th
          scope="col"
          style="width: 35%"
          class="text-right"
        />
      </tr>
    </thead>
    <tbody>
      <tr v-for="company in allCompanies" :key="company">
        <td scope="row" class="text-center">{{ company.id }}</td>
        <td>{{ company.companyName }}</td>
        <td class="text-right">
          <pencil-outline class="icons text-secondary" @click="isEditCompanyModalVisible = !isEditCompanyModalVisible; editCompany = company;" />
        </td>
      </tr>
      <transition name="animation">
        <adminEditCompanyModal
          v-if="isEditCompanyModalVisible"
          :edit-company="editCompany"
          @close="isEditCompanyModalVisible = !isEditCompanyModalVisible"
        />
      </transition>
    </tbody>
  </table>
</template>

<script>
import { PencilOutline } from 'mdue'
import adminEditCompanyModal from './adminEditCompanyModal'

export default {
  components: {
    PencilOutline,
    adminEditCompanyModal,
  },
  data () {
    return {
      isEditCompanyModalVisible: false,
      editCompany: '',
      newCompanyName: '',
      errors: '',
      success: '',
    }
  },
  computed: {
    allCompanies () {
      return this.$store.state.allCompanies
    },
  },
  mounted () {
    this.$store.dispatch('getAllCompanies')
  },
  methods: {
    async createNewCompany () {
      if(this.newCompanyName) {
        const companyName = this.newCompanyName
        await this.$store.dispatch('addNewCompany', { companyName })
          .then(response => {
            if(response){
              this.success = response.data
              this.$store.dispatch('getAllCompanies')
            }
          })
          .catch (error => {
            this.errors = error.response.data
          }) 
      } else {
        this.errors = 'Company name cannot be empty'
      }

    },
    clear () {
      this.newCompanyName = '',
      this.success = '',
      this.errors = ''
    }
  }
}
</script>