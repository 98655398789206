<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <slot name="body">
            <div class="modal-name">Editing company: <span class="text-success">{{ editCompany.companyName }}</span></div>
            <div class="form-group pt-2">
              <label for="newPassword">New company name</label>
              <input
                v-model="newCompanyName"
                type="text"
                class="form-control"
              >
            </div>
            <div style="height: 20px;">
              <p v-show="errors" class="text-danger"> {{ errors }}</p>
              <p v-show="success" class="text-success"> {{ success }}</p>
            </div>
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn btn-danger"
              @click="clear(); this.$emit('close');"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="updateCompany()"
            >
              Save User
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateCompany',
  props: {
    editCompany: {
      type: Object,
      required: true
    },
  },
  emits: ['close'],
  data () {
    return {
      newCompanyName: '',
      errors: '',
      success: '',
    }
  },
  mounted () {
    this.newCompanyName = this.editCompany.companyName
  },
  methods: {
    async updateCompany () {
      if (this.newCompanyName) {
        const payload = {
          companyId: this.editCompany.id,
          newCompanyName: this.newCompanyName,
        }
        await this.$store.dispatch('editCompany', payload)
          .then(response => {
            if(response){
              this.success = response.data
              this.$store.dispatch('getAllCompanies')
            }
          })
          .catch (error => {
            this.errors = error.response.data
          })
      } else {
        this.errors = 'Company name cannot be empty!'
      }
    },
    clear () {
      this.errors = ''
      this.success = ''
    }
  }
}
</script>
